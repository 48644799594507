<template>
  <div class="realtor">
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
      <a-row :gutter="8">
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="收款单号">
            <a-input v-model="searchData.collectionCode" placeholder="请输入订单编号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="订单编号">
            <a-input v-model="searchData.orderId" placeholder="请输入订单编号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="经销商名称">
            <a-input v-model="searchData.customerName" placeholder="请输入经销商名称"></a-input>
          </a-form-model-item>
        </a-col>

        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div>
      <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isOrderPriceSelect">查看</a-button>
      <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isOrderPriceAdd">新增</a-button>
<!--      <a-button type="primary"  @click="downItemExcel">导出Excel</a-button>-->
      <downLoad method="get" api="/api/customer/customer/financeReceipt/exportFinanceExcel" :params="searchData" name="收款单.xls" v-if="isOrderPriceExport"></downLoad>
<!--                  <a-button type="primary" @click="toHandler('edit')" icon="edit">编辑</a-button>-->
<!--                  <a-button type="default" @click="toHandler('del')" icon="delete" >删除</a-button>-->
    </div>
    <!-- 数据表格 -->
    <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
      :columns="columns" :data-source="tableData" bordered :pagination="page"
      @change="changeTable" :customRow="changeTableRow" :scroll="{ x: 1300 }">
      <span slot="collectionCode" slot-scope="text">
        <a-tooltip> <template slot="title"> {{text}} </template> {{text}} </a-tooltip>
      </span>
      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="paymentVoucher" slot-scope="item">
                <ZoomMedia style="height: 20px;" :value="item"></ZoomMedia>
            </span>
      <span slot="action" slot-scope="text, record" >
                <a @click="onCheck(record)">查看</a>
            </span>
    </a-table>
    <!-- 编辑表单 -->
    <FinanceReceipt-edit-modal ref="FinanceReceiptEditModal" @reload="getData"></FinanceReceipt-edit-modal>
    <!-- 查看-->
    <FinanceReceipt-info-modal ref="FinanceReceiptInfoModal" @reload="getData"></FinanceReceipt-info-modal>
  </div>
</template>

<script>
import {columns} from './components/colums.js'
import FinanceReceiptEditModal from './components/FinanceReceiptEditModal.vue'
import FinanceReceiptInfoModal from './components/FinanceReceiptInfoModal.vue'

import {delFinanceReceipt, listFinanceReceipt,downExcel} from './api/FinanceReceiptApi'
import {baseURL} from "@/utils/request";
import { checkPermission } from '@/utils/permissions'
import axios from "axios";
import {listCustomerInfo} from "@/views/customer/api/CustomerInfoApi";
const qs = require('qs')
const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default {
  name: 'financeReceipt',
  components: {
    FinanceReceiptEditModal,
    FinanceReceiptInfoModal
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: {y: 600},
      tableLoading: false, //表格loading
      page: Object.assign({}, pageSource),
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      isOrderPriceSelect: checkPermission('order:price:select'),
      isOrderPriceAdd:checkPermission('order:price:add'),
      isOrderPriceExport:checkPermission('order:price:export')
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        }
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    }
  },

  methods: {
    downItemExcel() {
      const _this = this
      const params = _this.tableData;
      downExcel (
        params
      ).then(res => {
          console.log('进不来')
      }).catch(error=>{
        const blob = new Blob([error], {
          type: 'application/vnd.ms-excel'
        });
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.download = '收款单';
        //a.click();
        //下面这个写法兼容火狐
        a.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        window.URL.revokeObjectURL(blob);
      })
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listFinanceReceipt({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        if (res.code === 200) {
          const {total, records} = res.body
          this.page.total = total
          this.tableData = records
        }
      }).finally(() => this.tableLoading = false)
    },

    // 查看
    onCheck(item) {
      const _this = this
      _this.$refs.FinanceReceiptInfoModal.setRowData(item, 'check')
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, pageSource)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, pageSource)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: e => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          }
        }
      }
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.FinanceReceiptEditModal.setRowData({}, 'add')
        return
      }

      // if (_this.selectedRows.length === 0) {
      //   _this.$notification.product({
      //     message: '请选择一条记录'
      //   })
      //   return
      // }
      switch (name) {
        case 'edit':
          _this.$refs.FinanceReceiptEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'export':
          window.location.href =
            `${process.env.VUE_APP_API_BASE_URL}/api/customer/customer/financeReceipt/excel=${this.selectedRowKeys[0]}`
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delFinanceReceipt(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.getData()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            }
          })
          break
        case 'check':
          _this.$refs.FinanceReceiptInfoModal.setRowData(_this.selectedRows[0], 'check')
          break
      }
     },
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
